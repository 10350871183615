import { createApp } from "vue";
import App from "./App.vue";
import { createMetaManager } from "vue-meta";
import router from "./router";
import axios from "axios";
import jquery from "jquery";
import mitt from "mitt";

const app = createApp(App);
const emitter = mitt();
global.jQuery = jquery;
var nowpurl = document.URL;
app.use(createMetaManager());
app.use(router);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$emitter = emitter;

app.config.globalProperties.$site = nowpurl.includes("localhost")
  ? "http://localhost:8080"
  : "https://rs.visionboy.me";
//app.config.globalProperties.$site = "http://localhost:8080"
app.mount("#app");
app.filter;

//createApp(App).use(createMetaManager()).use(router).mount('#app')
