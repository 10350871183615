import { createWebHistory, createRouter } from "vue-router";
import PostList from "../components/AbtList.vue";
import PostDetail from "../components/AbtDetail.vue";
import LoginPage from "../components/AbtLogin.vue";

const routes = [
  {
    path: "/",
    name: "List",
    component: PostList,
  },
  {
    path: "/tag/:tag",
    name: "Tags",
    component: PostList,
    props: true,
  },
  {
    path: "/find/:find",
    name: "Search",
    component: PostList,
    props: true,
  },
  {
    path: "/admin/:page",
    name: "Login",
    component: LoginPage,
    props: true,
  },
  {
    path: "/abtLogin",
    name: "Login",
    component: LoginPage,
    props: true,
  },
  {
    path: "/:no",
    name: "Detail",
    component: PostDetail,
    props: true,
  },
  {
    path: "/params",
    component: PostDetail,
    name: "Params",
    // true로 설정하면 데이터를 props로도 받습니다.
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
