<template>
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-transparent"
    :class="{ topNaviBgColor: topNaviBg }"
    color-on-scroll="100"
  >
    <div class="container" style="">
      <div class="navbar-translate">
        <a
          class="navbar-brand"
          href="/"
          rel="tooltip"
          title="Designed and Coded by Creative Tim"
          data-placement="bottom"
          style="padding-top: 0px !important"
        >
          <!-- <i class="fa-regular fa-face-smile-wink"></i> -->
          <i class="fa-regular fa-face-laugh-squint"></i>
          <span> VISIONBOY </span>
        </a>

        <!-- <a href="#"><i class="fa-brands fa-github"></i> </a> -->

        <button
          class="navbar-toggler navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-controls="navigation-index"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse justify-content-end" id="navigation">
        <div class="navbar-collapse-header">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a href="#" style="color: #fff">
                <i class="fa-regular fa-face-laugh-squint"></i>
                VISIONBOY
              </a>
            </div>
            <div class="col-6 collapse-close text-right">
              <button
                type="button"
                class="navbar-toggler fa-solid fa-xmark"
                data-toggle="collapse"
                data-target="#navigation"
                aria-controls="navigation-index"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i class="tim-icons icon-simple-remove"></i>
              </button>
            </div>
          </div>
        </div>
        <ul class="navbar-nav">
          <!-- Desctop nav -->

          <li class="dropdown nav-item" v-for="item in tabs" :key="item">
            <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
              <i v-bind:class="item.css"></i>{{ item.name }} ({{ item.sum }})
            </a>
            <div class="dropdown-menu dropdown-with-icons">
              <a
                class="dropdown-item"
                v-for="si in item.list"
                :key="si"
                @click="go(`/tag/${si.tagId}`)"
              >
                <i v-bind:class="si.css"></i>{{ si.name }}
                <span class="cnt">({{ si.cnt }})</span>
              </a>
            </div>
          </li>
          <li>
            <a
              class="nav-link"
              rel="tooltip"
              title="Albert Github"
              data-placement="bottom"
              href="https://github.com/visionboy"
              target="_blank"
            >
              <!-- <i class="fa-brands fa-github-alt"></i> -->
              <i class="fa-brands fa-github"></i> <span id="gitIco">GIT</span>
            </a>
          </li>
          <li style="padding-right: 0px">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="filter..."
                v-model="filterTxt"
                v-on:keyup.enter="filter()"
              />
              <div class="input-group-append">
                <a href="#" class="input-group-text" @click="filter()"
                  ><i class="fa-solid fa-magnifying-glass"></i
                ></a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      filterTxt: "",
      findTxt: "",
      topNaviBg: false,
      tabs: [],
      tagId: "",
    };
  },
  name: "AbtHeader",
  mounted() {
    this.$emitter.on("scrollEvt", this.getScrollInfo);
    this.$axios.get(this.$site + "/getTabInfos").then((res) => {
      this.tabs = res.data;
      for (var i = 0; i < res.data.length; i++) {
        var sum = 0;
        for (var k = 0; k < res.data[i].list.length; k++) {
          sum = sum + res.data[i].list[k].cnt;
        }
        res.data[i].sum = sum;
      }
      //console.log(res.data);
    });
  },
  props: {
    msg: String,
  },
  methods: {
    filter() {
      this.$emitter.emit("addTodoInput", this.filterTxt);
      var url = this.filterTxt == "" ? "/" : "/find/" + this.filterTxt;
      this.$router.push(url);
      //this.$emitter.emit("addTodoInput", this.filterTxt);
      var element = document.getElementById("navigation");
      element.classList.remove("show");
    },
    getScrollInfo(scrolls) {
      this.topNaviBg = scrolls;
    },
    go(url) {
      this.$router.push(url);
      this.$emitter.emit("routGo", url);
    },
  },
};
</script>
