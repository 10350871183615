<template>
    <div class="AbtLogin" style="padding:150px 0;width:500px;margin:0 auto">
        
        <div class="card-body" >
            <form class="form" action="/login">
            <div style="padding-bottom:5px">
                <input type="text" placeholder="ID" name="username" class="form-control" >
            </div>
            <div >
                   <input type="password" class="form-control" name="password" placeholder="Password" style="border-left:auto">
            </div>
            <div class="input-group">
                <button type="submit" class="btn btn-default" style="width:100%">LOGIN</button>
            </div>

            </form>
        </div>
    </div>
</template>


<script>

export default {
    
    data() {
        return {
            infos: [],
        }
    }
}
</script>