<template>
  <div class="">
    <div class="squares square3"></div>
    <div class="squares square4"></div>
    <div class="squares square5"></div>
    <div class="squares square6"></div>
    <div class="squares square7"></div>
    <div class="container">
      <div class="cttTop">
        <div
          class="card"
          v-for="item in abtList"
          :key="item"
          style="cursor: pointer"
        >
          <router-link :to="`/${item.no}`">
            <div class="card-header">
              {{ item.subject }}
              <span style="color: #c8efd4">[{{ item.pub_date }}]</span>
            </div>
            <div class="card-body">
              <div class="tab-content">
                <div class="tab-pane active">
                  {{ noHtml(item.content) }}
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <div class="card" v-if="abtList.length == 0">
          <div class="nothing">
            <i class="fa-solid fa-face-sad-cry"></i> Nothing ...
          </div>
        </div>

        <!-- <ul class="pagination justify-content-center" v-if="abtList.length > 0">
          <li class="page-item">
            <a
              class="page-link"
              href="#link"
              aria-label="Previous"
              @click="prev()"
            >
              <span aria-hidden="true"
                ><i class="fa-solid fa-angle-left" aria-hidden="true"></i
              ></span>
            </a>
          </li>
          <li
            class="page-item"
            v-for="item in pageList"
            :key="item"
            :class="{ active: item == page }"
          >
            <a class="page-link" href="#link" @click="getList(item)">{{
              item
            }}</a>
          </li>

          <li class="page-item">
            <a class="page-link" href="#link" aria-label="Next" @click="next()">
              <span aria-hidden="true"
                ><i class="fa-solid fa-angle-right" aria-hidden="true"></i
              ></span>
            </a>
          </li>
        </ul> -->
      </div>
    </div>
    <AbtLoad :posts="isLoading" />
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import AbtLoad from "./AbtLoad.vue";

export default {
  data() {
    return {
      abtList: [],
      page: 0,
      listNum: 10,
      totalNum: 0,
      pageList: [],
      pageNumCount: 5,
      totalPageNum: 0,
      curPage: 1,
      isEnd: false,
      filter: "",
      lastScrollPosition: 0,
      tagId: "",
      searchTxt: "",
      routeInfo: [],
      isLoading: false,
    };
  },
  components: { AbtLoad },

  computed: {
    noHtml() {
      return (v) => {
        let rst = v
          .replace(/&nbsp;/gi, " ")
          .replace(/<\/?[^>]+>/gi, " ")
          .replace(/&gt;/gi, " ");
        let cutLength = 500;
        // console.log(rst)
        return rst.length > cutLength
          ? rst.substring(0, cutLength - 3) + "..."
          : rst;
      };
    },
  },
  name: "AbtList",
  mounted() {
    const route = useRoute();
    if (route !== undefined) {
      this.tagId = route.params.tag;
      this.filter = route.params.find;
      console.log(route.params);
    }

    this.getMorePage(1);
    this.$emitter.on("addTodoInput", this.getFilterList);
    this.$emitter.on("getMore", this.getMorePage);
    this.$emitter.on("routGo", this.routerGos);
  },
  methods: {
    routerGos(tag) {
      this.tagId = tag.replace("/tag/", "");
      this.getFilterList("");
    },
    getFilterList(filterTxt) {
      this.filter = filterTxt;
      this.abtList = [];
      this.curPage = 1;
      this.isEnd = false;
      this.getMorePage();
    },
    getMorePage() {
      this.isLoading = true;
      if (!this.isEnd) {
        this.$axios
          .post(this.$site + "/getList", {
            perPage: 10,
            page: this.curPage,
            filterTxt: this.filter,
            table_id: this.tagId,
          })
          .then((res) => {
            if (res.data.list.length > 0) {
              //  console.log("thispage:" + this.curPage);
              this.abtList = this.abtList.concat(res.data.list);
              this.curPage++;
            } else {
              this.isEnd = true;
            }
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
      }
    },
    getList(page) {
      this.isLoading = true;
      this.$axios
        .post(this.$site + "/getList", {
          perPage: 10,
          page: page,
          filterTxt: this.filter,
          table_id: this.tagId,
        })
        .then((res) => {
          //console.log(res.data);

          this.page = page;
          this.abtList = res.data.list;
          this.totalNum = res.data.totalCount;
          this.totalPageNum = Math.ceil(res.data.totalCount / this.listNum);

          if (page % this.pageNumCount != 0) {
            this.pageList = [];
            var pageMin =
              Math.floor(page / this.pageNumCount) * this.pageNumCount + 1;
            var pageMax =
              pageMin + this.pageNumCount - 1 >= this.totalPageNum
                ? this.totalPageNum
                : pageMin + this.pageNumCount - 1;
            for (var i = pageMin; i <= pageMax; i++) {
              this.pageList.push(i);
            }
          }

          this.isLoading = false;
        });
    },
    prev() {
      if (this.page > 1) {
        this.getList(this.page - 1);
      }
    },
    next() {
      if (this.page < this.totalPageNum) {
        this.getList(this.page + 1);
      }
    },
  },
};
</script>
