<template>
  <metainfo></metainfo>
  <Header></Header>
  <div class="wrapper">
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import header from "./layout/AbtHeader.vue";
import footer from "./layout/AbtFoot.vue";
import { useMeta } from "vue-meta";

export default {
  data() {
    return {
      topNaviBg: false,
      rst: "",
      scrollUpdate: true,
    };
  },
  setup() {
    useMeta({
      title: "VISIONBOY - 나만의 코딩 놀이터",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "VISIONBOY 나만의 코딩 놀이터",
        },
        // {http-equiv:'Content-Security-Policy',content:'upgrade-insecure-requests'}
        {
          vmid: "keywords",
          name: "keywords",
          content:
            "VISIONBOY, 개발자,BIGDATA,ORACLE,JAVASCRIPT,JAVA,C#,vue,react,angular,rabbitmq",
        },
        // {vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1'}
      ],
    });
  },
  name: "App",
  components: {
    Header: header,
    Footer: footer,
  },
  mounted() {
    var nowpurl = document.URL;

    if (location.protocol != "https:" && !nowpurl.includes("localhost")) {
      location.href =
        "https:" +
        window.location.href.substring(window.location.protocol.length);
    }

    document.body.classList.add("index-page");
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.bundle.min.js"
    );
    document.head.appendChild(externalScript);
    document.addEventListener("scroll", this.scrollEvents);

    var meta = document.getElementsByTagName("meta");
    meta["viewport"].content =
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover";

    var geturl = document.referrer;

    var params = new URLSearchParams();
    params.append("geturl", geturl);
    params.append("rfs", "vue");
    params.append("tp", "poll");
    this.$axios.post("/main/ajax_pro.php", params);
  },
  unmounted() {
    document.removeEventListener("scroll", this.scrollEvents);
  },
  methods: {
    scrollEvents: function () {
      this.topNaviBg = window.scrollY > 20;
      this.$emitter.emit("scrollEvt", this.topNaviBg);

      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        this.scrollUpdate
      ) {
        this.scrollUpdate = false;
        this.$emitter.emit("getMore", this.topNaviBg);
        this.scrollUpdate = true;
      }
    },
  },
};
</script>

<style>
@import "./assets/css/googlefont.css";
@import "./assets/plugin/fontawesome/css/all.css";
@import "./assets/css/blk-design-system.css";
@import "./assets/css/abt.css";
@import "./assets/css/loading.css";
</style>
