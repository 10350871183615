<template>
    <div class="container cttTop cardDetail">
        <div class="card" style="background: #F9F9F9;">
            
            <div class="card-header">
                <div class="subWrap">
                    {{infos.subject}}
                    <p class="dateArea">{{infos.pub_date}}</p>
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content ">
                    <div class="tab-pane active" style="color: black;">
                         <span v-html="infos.content"></span>
                    </div>
                </div>
            </div>

            <p class="btnArea">
                <router-link :to="`/`">
                    <button type="button" class="btn btn-default">LIST</button>
                </router-link>
            </p>
        </div>

        <AbtLoad :posts="isLoading" />

    </div>

</template>


<script>
import { useRoute } from 'vue-router'
import AbtLoad from './AbtLoad.vue'

export default {
    
    data() {
        return {
            infos: [],
            isLoading:false
        }
    },
    components: { AbtLoad },
    mounted() {
  
        const route = useRoute()
        this.isLoading = true
        this.$axios.get(this.$site+"/getDetail?no="+route.params.no)
        .then(res => {
            res.data.content = res.data.content.replace("white-space: pre;","")
            res.data.content = res.data.content.replace("rgb(30, 30, 30);","rgb(30, 30, 30);border-radius:5px;padding:7px 10px;")
            this.infos = res.data;  

            // console.log(this.infos)
            document.title = res.data.subject
            document.getElementsByTagName('meta')[3].content=res.data.subject+",VISIONBOY (이성) 개인홈페이지"
            document.getElementsByTagName('meta')[4].content=res.data.subject+",VISIONBOY, 이성, 개발자,BIGDATA,ORACLE,JAVASCRIPT,JAVA,C#"
            this.isLoading = false
        })
    } 
}
</script>