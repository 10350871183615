<template>
    <footer class="footer">
      © 2014~{{thisYear}}  
      <a href="http://m.site.naver.com/03xiT" target="_blank" style="color:#fff;margin-right: 5px;" data-target="#myModal1" data-toggle="modal"><i class="fa-solid fa-qrcode"></i></a>
      <span style="color:#B6D9C6">Visitor: {{visitor}}</span>
    </footer>

    <!-- Mini Modal -->
    <div class="modal fade modal-mini modal-primary modal-mini" id="myModal1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content" style="background:#fff">
                <div class="modal-header" style="padding:0px" >
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        <i class="fa-solid fa-xmark" data-dismiss="modal" style="color:#171941"></i>
                    </button>
                    <a href="http://m.site.naver.com/03xiT" target="_blank"><img src="../assets/img/bigqr.jpg" ></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
      return {
        visitor:0,
        thisYear:new Date().getFullYear()
      }
    },
    name: 'AbtFooter',
    props: {
        msg: String
    },
    mounted() {
        this.$axios.get(this.$site+'/getVisitorCount').then(res => {
            this.visitor = res.data;
        })
    }
}
</script>
